const CUSTOM_EVENTS = Object.freeze({
    filter: {
        isEmpty: 'isEmpty', // Filter empty
        change: 'change', // Filter change
        result: 'result' // Filter result
    },
    accordion: {
        expand: 'expand', // Accordion expand
    },
    newDataProduct: {
        confirm: 'confirm', // Create a new data product
    },
    newUser: {
        confirm: 'confirm', // Create a new user
    }
})

export default CUSTOM_EVENTS