import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1cc51aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"grid","align-self":"start"} }
const _hoisted_2 = { class: "new-user" }
const _hoisted_3 = { class: "input-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "invalid-help-text"
}
const _hoisted_5 = { class: "input-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "invalid-help-text"
}
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "input-wrapper" }
const _hoisted_10 = {
  key: 1,
  class: "invalid-help-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
    }, _toDisplayString(_ctx.$t('users.newUser')), 3),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUser.userName.value) = $event)),
        onInput: _cache[1] || (_cache[1] = (e) => _ctx.newUser.userName.valid = _ctx.newUser.userName.value.length > 0),
        placeholder: "Username",
        class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
        type: "text"
      }, null, 34), [
        [_vModelText, _ctx.newUser.userName.value]
      ]),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUser.email.value) = $event)),
        placeholder: "E-mail",
        onInput: _cache[3] || (_cache[3] = (e) => _ctx.newUser.email.valid = e.target.validity.valid),
        class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
        type: "email",
        autocomplete: "new-text"
      }, null, 34), [
        [_vModelText, _ctx.newUser.email.value]
      ]),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newUser.firstName.value) = $event)),
        onInput: _cache[5] || (_cache[5] = (e) => _ctx.newUser.firstName.valid = _ctx.newUser.firstName.value.length > 0),
        placeholder: "First name",
        class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
        type: "text"
      }, null, 34), [
        [_vModelText, _ctx.newUser.firstName.value]
      ]),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newUser.lastName.value) = $event)),
        onInput: _cache[7] || (_cache[7] = (e) => _ctx.newUser.lastName.valid = _ctx.newUser.lastName.value.length > 0),
        placeholder: "Last name",
        class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
        type: "text"
      }, null, 34), [
        [_vModelText, _ctx.newUser.lastName.value]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUser.userPassword.value) = $event)),
          placeholder: "Password",
          class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
          type: "password",
          autocomplete: "new-password",
          onInput: _cache[9] || (_cache[9] = (e) => _ctx.newUser.userPassword.valid = e.target.validity.valid),
          onFocusout: _cache[10] || (_cache[10] = (e) => _ctx.newUser.userPassword.valid = e.target.validity.valid),
          pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{12,}$",
          title: "Enter a password 12 character long with at least: 1 uppercase, 1 digit, 1 special character"
        }, null, 34), [
          [_vModelText, _ctx.newUser.userPassword.value]
        ]),
        (!_ctx.newUser.userPassword.valid && _ctx.newUser.userPassword.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Invalid password (12 character long, 1 uppercase, 1 digit, 1 special character) "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.confirmPassword.value) = $event)),
          placeholder: "Confirm password",
          class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
          type: "password",
          autocomplete: "off",
          onInput: _cache[12] || (_cache[12] = (e) => _ctx.checkConfirmPassword(e.target)),
          onFocusout: _cache[13] || (_cache[13] = (e) => _ctx.checkConfirmPassword(e.target))
        }, null, 34), [
          [_vModelText, _ctx.confirmPassword.value]
        ]),
        (!_ctx.confirmPassword.valid && _ctx.confirmPassword.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, " The two passwords must match "))
          : _createCommentVNode("", true)
      ]),
      _withDirectives(_createElementVNode("select", {
        class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
        onChange: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.switchGroup && _ctx.switchGroup(...args))),
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newUser.group.value) = $event)),
        id: "role"
      }, [
        _createElementVNode("option", {
          style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }),
          value: null
        }, "Select an organization", 4),
        _createElementVNode("option", {
          style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }),
          value: undefined
        }, "+ Create a new organization", 4),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups.groups, (group) => {
          return (_openBlock(), _createElementBlock("option", {
            key: group,
            style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }),
            value: group.cn
          }, _toDisplayString(group.cn), 13, _hoisted_7))
        }), 128))
      ], 34), [
        [_vModelSelect, _ctx.newUser.group.value]
      ]),
      _withDirectives(_createElementVNode("select", {
        class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
        onChange: _cache[16] || (_cache[16] = (e) => _ctx.newUser.role.valid = _ctx.newUser.role.value !== null),
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.newUser.role.value) = $event)),
        id: "role"
      }, [
        _createElementVNode("option", {
          style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }),
          value: null
        }, "Select a role", 4),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ROLES, (role) => {
          return (_openBlock(), _createElementBlock("option", {
            key: role,
            style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }),
            value: role
          }, _toDisplayString(role), 13, _hoisted_8))
        }), 128))
      ], 34), [
        [_vModelSelect, _ctx.newUser.role.value]
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.newUser.group.value === undefined)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              onInput: _cache[18] || (_cache[18] = (e) => _ctx.checkNewGroupValidity(e.target)),
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.newGroup.value) = $event)),
              placeholder: "New organization name",
              class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
              type: "text"
            }, null, 34)), [
              [_vModelText, _ctx.newGroup.value]
            ])
          : _createCommentVNode("", true),
        (!_ctx.newGroup.valid && _ctx.newGroup.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Organization already exist "))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("a", {
      style: {"margin-top":"30px","justify-self":"center"},
      class: _normalizeClass(["stepper-btn", _ctx.disableCreateUser() ? 'disabled' : null]),
      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.createUser()))
    }, [
      (_ctx.userCreation)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "icon",
            icon: 'spinner'
          }))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.label)), 1)
    ], 2)
  ]))
}