import * as VueRouter from 'vue-router'
import KeycloakService from "@/services/AuthenticationService";
import ROLES from "@/constants/roles";

// Routes lazy loading
const Home = () => import("@/components/HomeComponent.vue")
const Domains = () => import("@/components/DomainsComponent.vue")
const Notifications = () => import("@/components/NotificationsComponent.vue")
const Deployments = () => import("@/components/DeploymentsComponent.vue")
const Continuum = () => import("@/components/ContinuumComponent.vue")
const NewDeployment = () => import("@/components/NewDeploymentComponent.vue")
const Domain = () => import("@/components/DomainDetail.vue")
const Deployment = () => import("@/components/DeploymentDetailComponent.vue")
const Settings = () => import("@/components/SettingsComponent.vue")
const Users = () => import("@/components/UsersComponent.vue")
const Benchmarking = () => import("@/components/Benchmarking/BenchmarkingToolComponent.vue")
const BenchmarkingComparison = () => import("@/components/Benchmarking/BenchmarkingComparisonComponent.vue")
// const NewUser = () => import("@/components/NewUserComponent.vue")
// const User = () => import("@/components/UserDetailComponent.vue")
const DataCatalog = () => import("@/components/DataCatalogComponent.vue")

// The label determines the presence of the route in the sidebar
// Use only the keys from i18n/i18n.ts.sidebar

const allRoles = Object.keys(ROLES).map((k: unknown) => ROLES[k as keyof typeof ROLES])

const routes = Object.freeze([
    {
        path: '/:catchAll(.*)',
        redirect: '/',
        meta: {
            label: false,
            authorized: allRoles
        }
    },
    {
        path: '/',
        component: Home,
        meta: {
            label: 'sidebar.home',
            authorized: allRoles
        }
    },
    {
        path: '/domains',
        query: false,
        name: 'domains',
        component: Domains,
        meta: {
            label: 'sidebar.domains',
            authorized: [
                ROLES.continuumAdministrator,
                ROLES.dataProductOwner
            ]
        },
        children: [
            {
                path: 'domain/:id',
                name: 'domain',
                component: Domain,
                props: true
            }
        ]
    },
    {   path: '/deployments',
        name: 'deployments',
        component: Deployments,
        meta: {
            label: 'sidebar.deployments',
            authorized: [
                ROLES.continuumAdministrator,
                ROLES.dataConsumer,
                ROLES.aerosUser
            ]
        },
        children: [
            {
                path: 'new',
                name: 'new-deployment',
                component: NewDeployment
            },
            {
                path: 'deployment/:id',
                component: Deployment,
                props: true
            }
        ]
    },
    {
        path: '/continuum',
        name: 'continuum' ,
        component: Continuum,
        meta: {
            label: 'Continuum',
            authorized: [
                ROLES.continuumAdministrator,
                ROLES.dataProductOwner
            ]
        }
    },
    {
        path: '/benchmark',
        name: 'benchmark',
        component: Benchmarking,
        meta: {
            label: 'Benchmarking',
            authorized: [
                ROLES.continuumAdministrator
            ]
        },
        children: [
            {
                path: 'compare',
                name: 'compare',
                props: {selectedBenchmark: true},
                component: BenchmarkingComparison
            }
        ]
    },
    {
        path: '/data-products',
        name: 'data-products',
        component: DataCatalog,
        meta: {
            label: 'Data products',
            authorized: [
                ROLES.continuumAdministrator,
                ROLES.dataProductOwner,
                ROLES.dataConsumer
            ]
        }
    },
    {
        path: '/notifications',
        name: 'notifications' ,
        component: Notifications,
        meta: {
            label: 'sidebar.notifications',
            authorized: allRoles
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            label: 'sidebar.users',
            authorized: [
                ROLES.continuumAdministrator
            ]
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            label: false,
            authorized: allRoles
        }
    }

])

const routerInit = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes
})


// Prevent user to access on non authorized routes
routerInit.beforeEach((to, from) => {
    const user = KeycloakService.User()
    const authorized: any = to.meta.authorized
    const auth: boolean | undefined = user?.realm_access?.roles?.some(r => authorized.includes(r))
    if (!auth) {
        // If a user is not authorized, redirect on the homepage
        return { path: "/" }
    }
    return user?.realm_access?.roles?.some(r => authorized.includes(r))
})

const router = {
    initialization: routerInit,
    routes: routes
}

export default router

