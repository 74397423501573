import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77d377c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "requirements" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"display":"flex","justify-content":"space-between","width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
    }, " IE " + _toDisplayString(_ctx.data.index) + " CLI arguments/ENV variables ", 3),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requirements, (requirement, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "requirement",
          key: requirement
        }, [
          _withDirectives(_createElementVNode("select", {
            style: {"width":"65px"},
            "onUpdate:modelValue": ($event: any) => ((requirement.type) = $event),
            class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
            id: "type"
          }, [
            _createElementVNode("option", {
              style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#0F0F0F' : '#ffffff'
            }),
              value: "CLI"
            }, " CLI ", 4),
            _createElementVNode("option", {
              style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#0F0F0F' : '#ffffff'
            }),
              value: "ENV"
            }, " ENV ", 4)
          ], 10, _hoisted_2), [
            [_vModelSelect, requirement.type]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
            "onUpdate:modelValue": ($event: any) => ((requirement.key) = $event),
            type: "text"
          }, null, 10, _hoisted_3), [
            [_vModelText, requirement.key]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
            "onUpdate:modelValue": ($event: any) => ((requirement.value) = $event),
            type: "text"
          }, null, 10, _hoisted_4), [
            [_vModelText, requirement.value]
          ]),
          _createElementVNode("a", {
            class: "remove",
            onClick: ($event: any) => (_ctx.removeRequirement(index))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "trash" })
          ], 8, _hoisted_5)
        ]))
      }), 128)),
      _createElementVNode("button", {
        class: "stepper-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addRequirement()))
      }, "New CLI arg/ENV variable")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('back'))),
        class: "confirm"
      }, "Back"),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm', {data: _ctx.requirements, index: _ctx.data.index, field: 'arguments'}))),
        class: "cancel"
      }, "Save")
    ])
  ], 64))
}