import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b75f30a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items" }
const _hoisted_2 = { class: "item-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.data.header), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.data, (item) => {
          return (_openBlock(), _createElementBlock("li", { key: item }, [
            _createElementVNode("p", null, _toDisplayString(item), 1)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}