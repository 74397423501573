import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eca586a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["toast", toast.success ? 'success' : 'fail']),
            style: {"margin-top":"10px"},
            key: toast
          }, [
            _createTextVNode(_toDisplayString(toast.label) + " ", 1),
            _createVNode(_component_font_awesome_icon, {
              style: {"margin-left":"10px","cursor":"pointer"},
              "aria-placeholder": "Remove",
              icon: ['fas', 'xmark'],
              onClick: ($event: any) => (_ctx.manualRemove(toast))
            }, null, 8, ["onClick"])
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}