import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-547378f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "details-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.header), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
          return (_openBlock(), _createElementBlock("li", { key: item }, [
            _createElementVNode("b", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: item?.icon
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(item.label) + ": ", 1)
            ]),
            _createElementVNode("span", {
              style: _normalizeStyle(_ctx.settingsStore.colorScheme === 'light' ? {'color': '#000000'} : null)
            }, _toDisplayString(item.value), 5)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}